





import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API_BASE_URL } from '../config/api'; 
import { Typography, CircularProgress } from '@mui/material';

const Partners = () => {
    const [testimonials, setTestimonials] = useState([]);

    useEffect(() => {
        fetchTestimonials();
    }, []);

    const fetchTestimonials = async () => {
        try {
            const { data } = await axios.get(`${API_BASE_URL}/api/users/testimonial`);
            console.log('test', data.data);
            setTestimonials(data.data);
        } catch (error) {
            console.error("Error fetching testimonials:", error);
        }
    };

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: true,
        swipeToSlide: true,
    };

    return (
        <div className=" pt-6 md:pt-12">
            <div className="px-4">
                <Typography variant="h3" className="font-bold font-primary  leading-tight text-gray-800 mb-8">
                    What our gym partners are <span className="text-red-600">saying</span>
                </Typography>
            </div>

            <div className="relative">
                <div className="flex items-center justify-between h-full w-full absolute z-0">
                    <div className="w-1/3 bg-white h-full" />
                    <div className="w-4/6 ml-8 sm:ml-12 md:ml-16 bg-red-50 h-full" />
                </div>

                <div className="xl:px-16 px-6 py-16 md:py-20 2xl:mx-auto 2xl:container relative z-40">
                    <Slider {...settings}>
                        {testimonials.length > 0 ? (
                            testimonials.map((testimonial, index) => {
                                const imageUrl = `${API_BASE_URL}${testimonial.image || "/default-image.png"}`;
                                console.log("Image URL:", imageUrl);
                                return (
                                    <div key={index}>
                                        <div className="flex flex-col md:flex-row">
                                            <div className="relative w-full md:w-1/2 h-64 md:h-96">
                                                <img
                                                    src={imageUrl}
                                                    alt={`Testimonial from ${testimonial.name}`}
                                                    className="w-full h-full object-cover shadow-lg rounded"
                                                    onError={(e) => {
                                                        console.error('Image failed to load:', imageUrl);
                                                        e.target.src = 'https://via.placeholder.com/150'; // Fallback to a valid placeholder image
                                                    }}
                                                />
                                                <div className="hidden md:flex items-center justify-center absolute top-0 right-0 w-20 h-20 sm:w-24 sm:h-24 bg-indigo-100 rounded-full -mt-10 -mr-10">
                                                    <img
                                                        src="https://tuk-cdn.s3.amazonaws.com/can-uploader/testimonial-svg1.svg"
                                                        alt="commas"
                                                    />
                                                </div>
                                            </div>

                                            <div className="mt-8 md:mt-0 md:ml-16 lg:ml-20 flex flex-col justify-between">
                                                <div>
                                                    <Typography variant="h6" className="font-semibold text-gray-800">
                                                        {testimonial.heading || "Testimonial Heading"}
                                                    </Typography>
                                                    <Typography variant="body1" className="leading-6 mt-4 text-gray-600 max-w-full sm:max-w-lg">
                                                        {testimonial.content || "Testimonial content goes here."}
                                                    </Typography>
                                                </div>
                                                <div className="mt-6">
                                                    <Typography variant="subtitle1" className="font-medium text-gray-800">
                                                        {testimonial.name || "Anonymous"}
                                                    </Typography>
                                                    <Typography variant="body2" className="text-gray-600">
                                                        {testimonial.designation || "Position/Role"}
                                                    </Typography>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="flex justify-center items-center py-10">
                                <CircularProgress className="text-red-600" />
                            </div>
                        )}
                    </Slider>
                </div>
            </div>
        </div>
    );
}

export default Partners;
