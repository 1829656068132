
import React, { useEffect, useState } from 'react';
import img1 from '../img/pngwing.com (12) 1.png';
import titleimg from "../img/pngwing.com 1.png";
import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Faq() {
  const [open, setOpen] = useState(null);
  const [faqData, setFaqData] = useState([]);
  const [error, setError] = useState(null);

  const toggleFAQ = (index) => {
    setOpen(open === index ? null : index);
  };

  // Fetch FAQ data from API
  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        const { data } = await axios.get(`${API_BASE_URL}/api/users/getfaq`);
        console.log('FAQ data:', data);

        if (data.status === 'success' && data.data.length > 0) {
          setFaqData(data.data); // Set the FAQ data received from API
        } else {
          setError('No FAQs found.');
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError('Content not found. Please check the API endpoint.');
        } else {
          setError(`Error fetching FAQs: ${err.message}`);
        }
        console.error('Error fetching FAQs:', err);
      }
    };

    fetchFaqData();
  }, []);

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations (1000ms = 1s)
      easing: 'ease-in-out', // Easing for animations
      once: false, // Disable this to trigger animation every time element comes into view
      mirror: true, // Repeat animation when scrolling up and down
    });
  }, []);

  return (
    <div id='FAQ'>
      <div className="p-6 mt-24 font-[Archivo]">
        <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-12">
          
          {/* Main Image and Text */}
          <div className="relative md:flex-1 text-center md:text-left" data-aos="fade-right">
            <h2 className="absolute inset-0 bottom-[600px] lg:left-32 text-[2.5rem] sm:text-[3.5rem] md:text-[4.5rem] font-extrabold text-black leading-tight flex items-center justify-center md:justify-start">
              <span>FIT</span>
              <span className="text-[#DA0000]">ESPER</span>
              <img src={titleimg} alt="Title" className="ml-2 w-10 sm:w-12 md:w-14" />
            </h2>
            <img
              src={img1}
              alt="Image description"
              loading="lazy"
              title="Load"
              className="relative w-full max-w-xs mx-auto md:max-w-md"
              data-aos="zoom-in" // Adding animation to the image
            />
          </div>

          {/* FAQ Section */}
          <div className="w-full md:flex-1 p-6 flex flex-col items-center md:items-start md:text-left text-center" data-aos="fade-left">
            <h3 className="text-xl font-bold text-[#898989] mb-2">FAQs</h3>
            <h3 className="text-3xl font-bold text-[#FF3A3A] mb-2">Frequently Asked Questions</h3>
            <h3 className="text-[#898989] mb-6">Welcome to Your Fitness Journey: Your Go-To Guide for Gym FAQs!</h3>

            {/* Error Message */}
            {error && <p className="text-red-500">{error}</p>}

            {/* FAQ List */}
            <div className="space-y-4 w-full">
              {faqData.length > 0 ? (
                faqData.map((faq, index) => (
                  <div key={index} className="border-b pb-4 w-full" data-aos="fade-up">
                    <button
                      onClick={() => toggleFAQ(index)}
                      className="flex justify-between items-center w-full text-left text-lg text-white bg-black p-2 rounded-md focus:outline-none"
                    >
                      <span>{faq.question}</span>
                      <span className="ml-2">{open === index ? '-' : '+'}</span>
                    </button>
                    {open === index && (
                      <p className="mt-2 bg-black rounded-md p-2 text-white" data-aos="fade-down">
                        {faq.answer}
                      </p>
                    )}
                  </div>
                ))
              ) : (
                <p>No FAQs available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
