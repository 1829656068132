

import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Navbar from './component/Navbar'; 

import Home from './component/Home';
import RoadMap from './component/RoadMap';
import Blog from './component/Blog';

import About from './component/About';

const App = () => {
  return (
    <div className="">
<Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/roadmap" element={<RoadMap />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about" element={<About />} />
      </Routes>
    </Router>
</div>
  );
};

export default App;
