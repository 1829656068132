

import React, { useEffect, useState } from 'react';
import { FaFacebook, FaInstagramSquare, FaYoutube, FaTwitter } from "react-icons/fa";
import axios from 'axios';
import homeimg from "../img/pngwing.com (27) 1.png";
import circle from "../img/circlepng.png";
import titleimg from "../img/pngwing.com 1.png";
import { API_BASE_URL } from '../config/api';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Landing() {
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [socialLinks, setSocialLinks] = useState([]);
  const [error, setError] = useState(null);

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: true, 
   
    });
  }, []);

  // Fetch content data
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const { data } = await axios.get(`${API_BASE_URL}/api/users/content`);
      setUsers(data.data);
      if (data.data.length > 0) {
        setCurrentUser(data.data[0]);
      }
    } catch (err) {
      setError(`Error fetching users: ${err.message}`);
      console.error('Error fetching users:', err);
    }
  };

  // Fetch social links
  useEffect(() => {
    const fetchSocialLinks = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/api/users/social`);
        const data = await response.json();
        console.log('Fetched social links data:', data);
        if (data.status === 'success' && Array.isArray(data.data)) {
          setSocialLinks(data.data);
        } else {
          setError('No social links found.');
        }
      } catch (error) {
        console.error("Error fetching social media links:", error);
      }
    };

    fetchSocialLinks();
  }, []);

  // Function to render social icons
  const renderSocialIcon = (icon) => {
    switch (icon.toLowerCase()) {
      case 'facebook':
        return <FaFacebook />;
      case 'instagram':
        return <FaInstagramSquare />;
      case 'youtube':
        return <FaYoutube />;
      case 'twitter':
        return <FaTwitter />;
      default:
        return null;
    }
  };

  return (
    <div className="relative mb-12 font-[Archivo] mt-6 px-4 sm:px-6 md:px-8 lg:px-12 bg-white flex flex-col lg:flex-row lg:justify-between">
      {/* Text Section */}
      <div
        className="text-[#DA0000] space-y-6 mt-8 sm:mt-10 md:mt-12 flex-1"
        data-aos="fade-right " // Apply AOS effect
        data-aos-duration="1000"
      >
        {error ? (
          <Typography color="error">{error}</Typography>
        ) : (
          <>
            <Typography
              variant="h6"
              component="h2"
              className="font-bold"
              data-aos="fade-up" // Apply AOS effect
            >
              {currentUser?.content || 'BUILD YOUR'}
            </Typography>
            <Typography
              variant="h3"
              component="h1"
              className="text-4xl sm:text-5xl md:text-6xl font-bold flex items-center"
              data-aos="fade-up" // Apply AOS effect
              data-aos-delay="200"
            >
              <span className="text-black">FIT</span> ESPER
              <img src={titleimg} alt="Title" className="ml-2 w-12 sm:w-14 md:w-16" />
            </Typography>
            <Typography
              variant="body1"
              className="text-black text-xs sm:text-sm md:text-base lg:text-lg font-semibold w-full lg:w-[80%]"
              data-aos="fade-up" // Apply AOS effect
              data-aos-delay="400"
            >
              {currentUser?.section || 
                `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
            </Typography>
          </>
        )}
        <div className='mt-4 ' data-aos="fade-up" data-aos-delay="600">
          <Link to='/about'>
            <Button
              variant="contained"
              className="font-semibold px-4  sm:px-6 py-2 transition duration-300 ease-in-out"
              style={{ backgroundColor: '#f00', color: '#fff' }}
            >
              Read More
            </Button>
          </Link>
        </div>

        {/* Social Links Section */}
        <div className="relative mt-12 sm:mt-10 lg:top-28 md:mt-12" data-aos="fade-right">
          <Typography variant="body2" className="text-black font-semibold">Follow Us</Typography>
          <div className="flex space-x-4 text-xl">
            {socialLinks.length > 0 ? (
              socialLinks.map((social) => (
                <a key={social.id} href={social.link} target="_blank" rel="noopener noreferrer">
                  {renderSocialIcon(social.icon)}
                </a>
              ))
            ) : (
              <Typography>No social media links available.</Typography>
            )}
          </div>
        </div>
      </div>

      {/* Image Section */}
      <div
        className="md:w-1/2 lg:mt-12 mt-12 flex justify-center items-center relative"
        data-aos="zoom-in" // Apply AOS effect
      >
        <img
          src={circle}
          alt="Background Box"
          loading="lazy"
          className="w-full circle max-w-sm object-contain absolute z-10"
          style={{ maxWidth: "100%" }}
        />
        <img
          src={homeimg}
          alt="Fitness Tips"
          loading="lazy"
          className="w-full max-w-sm object-contain z-20 relative"
          style={{ maxWidth: "70%" }}
        />
      </div>
    </div>
  );
}

export default Landing;
