










import React from "react";
import topimg from "../img/pngwing.com (2).png";
// import topimg from "../img/gymimage55.png";
import sideimg from "../img/pngwing.com (30) 1.png";
import sidebox from "../img/gymimagebox2.png";
import Footer from "../Pages/Footer";

function Blog() {
  return (
<div>
<div className="font-[AnekBangla] p-6 ">
      {/* Header */}
      <div className="flex flex-col items-center mb-8">
  <div className="relative">
    <h2 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-center sm:absolute sm:left-1/2 sm:transform sm:-translate-x-1/2   relative  top-8">
      BLOG
    </h2>
  </div>
  <img
    src={topimg}
    alt="Top Image"
    className="w-full max-w-4xl object-cover mt-4 lg:mt-8"
  />
</div>


      {/* Section 1: Fitness & Training Tips */}
      <div className="flex flex-col md:flex-row justify-between items-center mb-16 space-y-8 md:space-y-0">
        <div className="md:w-1/2 space-y-6 px-4">
          <h2 className="text-3xl font-bold leading-tight text-gray-900">
            Fitness & Training
            <span className="text-red-600"> Tips</span>
          </h2>
          <p className="text-lg text-gray-600">
            Provide valuable advice and insights to help users improve their
            workouts and achieve their fitness goals.
          </p>
          <p className="text-base font-[AnekBangla] text-gray-700">
            Workout Routines: “5 Effective Full-Body Workouts You Can Do at
            Home” or “The Ultimate Strength Training Routine for Beginners.”
            Exercise Techniques: “How to Perfect Your Squat Form” or “Common
            Mistakes to Avoid in Your Deadlift.” Training Programs: “How to
            Design a Balanced Training Program for Weight Loss” or
            “High-Intensity Interval Training (HIIT) for Maximum Results.”
            Recovery Tips: “The Best Stretches for Post-Workout Recovery” or
            “How to Prevent and Treat Exercise-Related Injuries.”
          </p>
        </div>

        <div className="md:w-1/2 flex justify-center items-center relative px-4">
          <img
            src={sidebox}
            alt="Background Box"
            loading="lazy"
            className="absolute z-0 w-80 h-80 object-contain"
          />
          <img
            src={sideimg}
            alt="Fitness Tips"
            loading="lazy"
            className="relative z-10 w-80 h-80 object-contain"
          />
        </div>
      </div>

      {/* Section 2: Nutrition & Wellness */}
      <div className="flex flex-col md:flex-row-reverse justify-between items-center mb-16 space-y-8 md:space-y-0">
        <div className="md:w-1/2 space-y-6 px-4">
          <h2 className="text-3xl font-bold leading-tight text-gray-900">
            Nutrition &
            <span className="text-red-600"> Wellness</span>
          </h2>
          <p className="text-lg text-gray-600">
            Educate readers on the importance of nutrition, hydration, and
            overall wellness in achieving their fitness goals.
          </p>
          <p className="text-base font-[AnekBangla] text-gray-700">
            Healthy Recipes: “10 Quick and Nutritious Post-Workout Snacks” or
            “Easy Meal Prep Ideas for a Healthier Diet.” Nutritional Advice:
            “How to Fuel Your Workouts: Essential Nutrients for Optimal
            Performance” or “Understanding Macronutrients and Their Role in
            Fitness.” Wellness Tips: “The Benefits of Proper Hydration and How
            Much Water You Really Need” or “How to Balance Your Workouts and
            Rest for Optimal Health.” Supplement Guides: “Do You Need
            Supplements? A Guide to Essential Fitness Supplements” or “How to
            Choose the Right Protein Powder for Your Goals.”
          </p>
        </div>

        <div className="md:w-1/2 flex justify-center items-center relative px-4">
          <img
            src={sidebox}
            alt="Background Box"
            loading="lazy"
            className="absolute z-0 w-80 h-80 object-contain"
          />
          <img
            src={sideimg}
            alt="Fitness Tips"
            loading="lazy"
            className="relative z-10 w-80 h-80 object-contain"
          />
        </div>
      </div>

      {/* Section 3: Community & Success Stories */}
      <div className="text-center space-y-6 p-8 ">
        <h2 className="text-3xl font-bold leading-tight text-gray-900">
          Community & Success
          <span className="text-red-600"> Stories</span>
        </h2>
        <p className="text-base font-[AnekBangla] text-gray-700 max-w-4xl mx-auto">
          Member Spotlights: “Meet Jane: How She Lost 30 Pounds and Transformed
          Her Life at Our Gym” or “John’s Journey: From Couch Potato to Marathon
          Runner.” Gym Events: “Highlights from Our Recent Fitness Challenge and
          How Members Performed” or “Upcoming Events You Don’t Want to Miss!”
          Success Tips: “How Our Members Achieve Their Goals: Top Tips from
          Successful Gym Goers” or “What Keeps Our Members Motivated? Insights
          from Our Community.” Trainer Advice: “Trainer Q&A: Top Questions
          Answered by Our Expert Coaches” or “How Our Trainers Help Members
          Overcome Fitness Plateaus.”
        </p>
      </div>
    </div>

    <div>
      <Footer/>
    </div>
</div>
  );
}

export default Blog;
