










// import React, { useEffect, useState } from 'react';
// import imgBoy from "../img/pngwing.com (39).png";
// import img2 from "../img/pngwing.com (26) 1.png";
// import sidebox from "../img/gymimagebox1.png";
// import Footer from "../Pages/Footer";
// import axios from 'axios';
// import {API_BASE_URL} from '../config/api'

// // export const API_BASE_URL = 'http://localhost:5000/api/users';

// function RoadMap() {
//   const [content, setContent] = useState([]);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchContent = async () => {
//       try {
//         const { data } = await axios.get(`${API_BASE_URL}/api/users/roadmap`);
//         console.log('Fetched content:', data); // Debugging
//         if (data.status === 'success' && Array.isArray(data.data) && data.data.length > 0) {
//           setContent(data.data);
//         } else {
//           setError('No content found.');
//         }
//       } catch (err) {
//         setError(`Error fetching content: ${err.message}`);
//         console.error('Error fetching content:', err);
//       }
//     };

//     fetchContent();
//   }, []);

//   return (
//     <div>
//       <div className="py-8 mt-20 px-4 md:px-12 font-[AnekBangla] lg:px-20 space-y-16">
//         <div className="flex items-center mb-32 text-white p-2 px-8 rounded-md bg-[#BF0000] space-y-6 md:space-y-0 md:flex-row md:space-x-4 space-x-20 lg:space-x-8">
//           <div className="lg:w-[100px] w-[100px] ">
//             <img
//               src={imgBoy}
//               alt="Boy"
//               className="w-full h-auto"
//               loading="lazy"
//             />
//           </div>
//           <div className="text-center md:text-center md:w-auto md:flex-grow">
//             <h2 className="text-[2rem]">
//               Welcome To Our Gym <span className="text-black">FIT ESPERO</span>
//             </h2>
//           </div>
//           <div className="w-[100px] md:w-[150px] lg:w-[200px]">
//             <img src={img2} alt="Decoration" className="w-full h-auto" loading="lazy" />
//           </div>
//         </div>

//         {/* Roadmap Section */}
//         <div className="space-y-12">
//           {content.length > 0 ? (
//             content.map((item, index) => (
//               <div key={index} className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'} items-center space-y-8 md:space-y-0 md:space-x-0 lg:space-x-12`}>
//                 <div className="md:w-1/2 space-y-4 px-4 md:px-0">
//                   <h2 className="text-2xl lg:text-3xl font-bold text-gray-800">
//                     {item.heading} <span className="text-red-600">Tips</span>
//                   </h2>
//                   <p className="text-gray-600">{item.subheading}</p>
//                   <p className="text-sm lg:text-base text-gray-600">{item.content}</p>
//                 </div>
//                 <div className="md:w-1/2 flex justify-center items-center relative">
//   <img
//     src={sidebox}
//     alt="Background Box"
//     loading="lazy"
//     className="w-full max-w-sm object-contain absolute z-10"
//     style={{ right: "10%", maxWidth: "100%" }}
//   />
//   <img
//     src={`http://localhost:5000${item.imageurl.replace(/\\/g, '/')}`} // Use the imageurl from the API
//     alt="Fitness Tips"
//     loading="lazy"
//     className="w-full max-w-sm object-contain z-20 relative"
//   />
// </div>

//               </div>
//             ))
//           ) : (
//             <p className="text-red-500">{error || 'Loading content...'}</p>
//           )}
//         </div>
//       </div>

//       <Footer />
//     </div>
//   );
// }

// export default RoadMap;






















import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import imgBoy from "../img/pngwing.com (39).png";
import img2 from "../img/pngwing.com (26) 1.png";
import sidebox from "../img/gymimagebox1.png";
import Footer from "../Pages/Footer";
import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import { Card, CardContent, Typography, Grid } from '@mui/material';

function RoadMap() {
  const [content, setContent] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of the animation (optional, adjust as needed)
    });
    
    const fetchContent = async () => {
      try {
        const { data } = await axios.get(`${API_BASE_URL}/api/users/roadmap`);
        console.log('Fetched content:', data); // Debugging
        if (data.status === 'success' && Array.isArray(data.data) && data.data.length > 0) {
          setContent(data.data);
        } else {
          setError('No content found.');
        }
      } catch (err) {
        setError(`Error fetching content: ${err.message}`);
        console.error('Error fetching content:', err);
      }
    };

    fetchContent();
  }, []);

  return (
    <div>
      <div className="py-8 mt-20 px-4 md:px-12 font-[AnekBangla] lg:px-20 space-y-16">
        <div className="flex items-center mb-32 text-white p-2 px-8 rounded-md bg-[#BF0000] space-y-6 md:space-y-0 md:flex-row md:space-x-4 space-x-20 lg:space-x-8"
          data-aos="fade-up" // Apply AOS effect here
        >
          <div className="lg:w-[100px] w-[100px]">
            <img
              src={imgBoy}
              alt="Boy"
              className="w-full h-auto"
              loading="lazy"
            />
          </div>
          <div className="text-center md:text-center md:w-auto md:flex-grow">
            <Typography variant="h4" className="text-[2rem]">
              Welcome To Our Gym <span className="text-black">FIT ESPERO</span>
            </Typography>
          </div>
          <div className="w-[100px] md:w-[150px] lg:w-[200px]">
            <img src={img2} alt="Decoration" className="w-full h-auto" loading="lazy" />
          </div>
        </div>

        {/* Roadmap Section */}
        <div className="space-y-12">
          {content.length > 0 ? (
            content.map((item, index) => (
              <div key={index} className={`flex flex-col ${index % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'} items-center space-y-8 md:space-y-0 md:space-x-0 lg:space-x-12`}
                data-aos={index % 2 === 0 ? "fade-right" : "fade-left"} // Apply alternating AOS effects
              >
                <div className="md:w-1/2 space-y-4 px-4 md:px-0">
                  <h2 className="text-2xl lg:text-3xl font-bold text-gray-800">
                    {item.heading} <span className="text-red-600">Tips</span>
                  </h2>
                  <p className="text-gray-600">{item.subheading}</p>
                  <p className="text-sm lg:text-base text-gray-600">{item.content}</p>
                </div>
                <div className="md:w-1/2 flex justify-center items-center relative">
                  <img
                    src={sidebox}
                    alt="Background Box"
                    loading="lazy"
                    className="w-full max-w-sm object-contain absolute z-10"
                    style={{ right: "10%", maxWidth: "100%" }}
                  />
                  

  <img
    src={`${API_BASE_URL}${item.imageurl.replace(/\\/g, '/')}`} 
    alt="Fitness Tips"
    loading="lazy"
    className="w-full max-w-sm object-contain z-20 relative"
  />
</div>

              </div>
            ))
          ) : (
            <p className="text-red-500">{error || 'Loading content...'}</p>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default RoadMap;






















