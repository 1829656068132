



// import React, { useEffect, useState } from 'react';
// import { IoCall } from "react-icons/io5";
// import img1 from '../img/Image.png';
// import { FaLocationDot } from "react-icons/fa6";
// import axios from 'axios';
// import { API_BASE_URL } from '../config/api';
// import { TextField, Button, Typography, Card, CardContent } from '@mui/material';
// import AOS from 'aos';
// import 'aos/dist/aos.css';  // Import AOS styles

// function Contact() {
//   const [content, setContent] = useState({ id: '', phone: '', headoffice: '', branch: '' });
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     // Initialize AOS animations
//     AOS.init({
//       duration: 1000, // Duration of animations (1000ms = 1s)
//       easing: 'ease-in-out', // Easing for animations
//       once: false, // Disable this to trigger animation every time element comes into view
//       mirror: true, 
//     });

   
//     const fetchContent = async () => {
//       try {
//         const { data } = await axios.get(`${API_BASE_URL}/api/users/contact`);
//         console.log('Fetched contact:', data);
//         if (data.status === 'success' && data.data && Array.isArray(data.data) && data.data.length > 0) {
//           setContent(data.data[0]);
//         } else {
//           setError('No content found.');
//         }
//       } catch (err) {
//         if (err.response && err.response.status === 404) {
//           setError('Content not found. Please check the API endpoint.');
//         } else {
//           setError(`Error fetching content: ${err.message}`);
//         }
//         console.error('Error fetching content:', err);
//       }
//     };

//     fetchContent();
//   }, []);

//   return (
//     <div id="contact" className="p-6 font-[Archivo] mb-6 md:mb-16 space-y-12">
//       <div className="text-center space-y-6 p-6 md:p-8" data-aos="fade-up">
//         <Typography variant="h3" className="text-4xl md:text-5xl font-extrabold text-red-700 drop-shadow-md">
//           Our E-Commerce Partners
//         </Typography>
//         <Typography variant="h4" className="text-xl md:text-2xl text-gray-700 italic">
//           Coming Soon
//         </Typography>
//         <div className="flex justify-center mt-4">
//           <span className="inline-block w-20 h-1 bg-red-600 rounded-full"></span>
//         </div>
//       </div>

//       <div className="flex flex-col lg:flex-row lg:space-x-8 items-stretch">
//         <Card className="lg:w-2/5 shadow-lg p-6 space-y-8 text-center lg:text-left" data-aos="zoom-in">
//           <CardContent>
//             <Typography variant="h4" className="text-3xl md:text-4xl font-bold text-gray-900">
//               Contact Us
//             </Typography>
//             <div className="space-y-6 text-gray-700">
//               <div className="flex items-center space-x-4">
//                 <IoCall className="w-10 h-10 p-2 bg-red-600 text-white rounded-full" />
//                 <Typography className="text-base md:text-lg">{content.phone || '+91 8649736123'}</Typography>
//               </div>
//               <div className="flex items-center space-x-4">
//                 <FaLocationDot className="w-10 h-10 p-2 bg-red-600 text-white rounded-full" />
//                 <Typography className="text-base md:text-lg leading-relaxed">
//                   <strong>Head office:</strong> {content.headoffice || '513/C-12 Ground Floor KH No.-922 Om Nagar Badarpur New Delhi-110044'}
//                 </Typography>
//               </div>
//               <div className="flex items-center space-x-4">
//                 <FaLocationDot className="w-10 h-10 p-2 bg-red-600 text-white rounded-full" />
//                 <Typography className="text-base md:text-lg leading-relaxed">
//                   <strong>Head office:</strong> {content.headoffice || '513/C-12 Ground Floor KH No.-922 Om Nagar Badarpur New Delhi-110044'}
//                 </Typography>
//               </div>
//               <div className="flex items-center space-x-4">
//                 <FaLocationDot className="w-10 h-10 p-2 bg-red-600 text-white rounded-full" />
//                 <Typography className="text-base md:text-md leading-relaxed">
//                   <strong>Branch office:</strong> {content.branch || 'Zone No.-3, C-Block, P.O.+ P.S.- Birsa Nagar, Town Jamshedpur, Dist : East Singhbum, Jharkhand-831004'}
//                 </Typography>
//               </div>
//             </div>
//           </CardContent>
//           <div className="flex justify-center" data-aos="fade-in">
//             <img src={img1} alt="Map" title="Map" className="rounded-lg shadow-xl w-full max-w-lg" />
//           </div>
//         </Card>

//         <Card className="lg:w-3/5 lg:mt-0 mt-12 bg-red-600 text-white p-4 md:p-10 rounded-xl flex flex-col justify-between" data-aos="slide-up" style={{ height: '100%' }}>
//           <CardContent>
//             <Typography variant="h4" className="text-2xl md:text-3xl font-bold">
//               Reach Out and Transform <span className=' text-red-600'>Your Fitness Journey</span> Today
//             </Typography>
//             <Typography variant="h4" className="text-lg text-red-600 md:text-xl">
//               Get in Touch
//             </Typography>
//             <form className="space-y-4 mt-4" data-aos="fade-up">
//               <TextField label="Name" variant="outlined" fullWidth placeholder="Your Name" required />
//               <TextField label="Email" variant="outlined" fullWidth placeholder="Your Email" required type="email" />
//               <TextField
//                 label="Message"
//                 variant="outlined"
//                 fullWidth
//                 placeholder="Your Message"
//                 required
//                 multiline
//                 rows={4}
//               />
//               <Button variant="contained" type="submit" className="w-full bg-gradient-to-r from-[#FF0000] to-[#6D0000] text-white font-semibold">
//                 Send
//               </Button>
//             </form>
//           </CardContent>
//         </Card>
//       </div>
//     </div>
//   );
// }

// export default Contact;

















import React, { useEffect, useState } from 'react';
import { IoCall } from "react-icons/io5";
import img1 from '../img/Image.png';
import { FaLocationDot } from "react-icons/fa6";
import axios from 'axios';
import { API_BASE_URL } from '../config/api';
import { TextField, Button, Typography, Card, CardContent } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css';  // Import AOS styles

function Contact() {
  const [content, setContent] = useState({ id: '', phone: '', headoffice: '', branch: '' });
  const [error, setError] = useState(null);

  useEffect(() => {
    AOS.init({
      duration: 1000, 
      easing: 'ease-in-out', 
      once: false, 
      mirror: true, 
    });

    const fetchContent = async () => {
      try {
        const { data } = await axios.get(`${API_BASE_URL}/api/users/contact`);
        if (data.status === 'success' && data.data && Array.isArray(data.data) && data.data.length > 0) {
          setContent(data.data[0]);
        } else {
          setError('No content found.');
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError('Content not found. Please check the API endpoint.');
        } else {
          setError(`Error fetching content: ${err.message}`);
        }
      }
    };

    fetchContent();
  }, []);

  return (
    <div id="contact" className="p-6 font-[Archivo] mb-6 md:mb-16 space-y-12">
      <div className="text-center space-y-6 p-6 md:p-8" data-aos="fade-up">
        <Typography variant="h3" className="text-4xl md:text-5xl font-extrabold text-red-700 drop-shadow-md">
          Our E-Commerce Partners
        </Typography>
        <Typography variant="h4" className="text-xl md:text-2xl text-gray-700 italic">
          Coming Soon
        </Typography>
        <div className="flex justify-center mt-4">
          <span className="inline-block w-20 h-1 bg-red-600 rounded-full"></span>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-8 items-stretch">
        <Card className="lg:w-2/5 shadow-lg p-6 space-y-8 text-center lg:text-left" data-aos="zoom-in">
          <CardContent>
            <Typography variant="h4" className="text-3xl md:text-4xl font-bold text-gray-900">
              Contact Us
            </Typography>
            <div className="space-y-6 text-gray-700">
              {/* For small screens, icons and text centered */}
              <div className="flex flex-col items-center space-y-2 md:flex-row md:space-x-4">
                <IoCall className="w-10 h-10 p-2 bg-red-600 text-white rounded-full" />
                <Typography className="text-base md:text-lg text-center md:text-left">{content.phone || '+91 8649736123'}</Typography>
              </div>
              <div className="flex flex-col items-center space-y-2 md:flex-row md:space-x-4">
                <FaLocationDot className="w-10 h-10 p-2 bg-red-600 text-white rounded-full" />
                <Typography className="text-base md:text-lg text-center md:text-left">
                  <strong>Head office:</strong> {content.headoffice || '513/C-12 Ground Floor KH No.-922 Om Nagar Badarpur New Delhi-110044'}
                </Typography>
              </div>
              <div className="flex flex-col items-center space-y-2 md:flex-row md:space-x-4">
                <FaLocationDot className="w-10 h-10 p-2 bg-red-600 text-white rounded-full" />
                <Typography className="text-base md:text-md text-center md:text-left">
                  <strong>Branch office:</strong> {content.branch || 'Zone No.-3, C-Block, P.O.+ P.S.- Birsa Nagar, Town Jamshedpur, Dist : East Singhbum, Jharkhand-831004'}
                </Typography>
              </div>
            </div>
          </CardContent>
          <div className="flex justify-center" data-aos="fade-in">
            <img src={img1} alt="Map" title="Map" className="rounded-lg shadow-xl w-full max-w-lg" />
          </div>
        </Card>

        <Card className="lg:w-3/5 lg:mt-0 mt-12 bg-red-600 text-white p-4 md:p-10 rounded-xl flex flex-col justify-between" data-aos="slide-up" style={{ height: '100%' }}>
          <CardContent>
            <Typography variant="h4" className="text-2xl md:text-3xl font-bold">
              Reach Out and Transform <span className="text-red-600">Your Fitness Journey</span> Today
            </Typography>
            <Typography variant="h4" className="text-lg text-red-600 md:text-xl">
              Get in Touch
            </Typography>
            <form className="space-y-4 mt-4" data-aos="fade-up">
              <TextField label="Name" variant="outlined" fullWidth placeholder="Your Name" required />
              <TextField label="Email" variant="outlined" fullWidth placeholder="Your Email" required type="email" />
              <TextField
                label="Message"
                variant="outlined"
                fullWidth
                placeholder="Your Message"
                required
                multiline
                rows={4}
              />
              <Button variant="contained" type="submit" className="w-full bg-gradient-to-r from-[#FF0000] to-[#6D0000] text-white font-semibold">
                Send
              </Button>
            </form>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default Contact;
