






// import React from "react";
// import img1 from "../img/Asset 1@2x 1.png";
// import img3 from '../img/gymimage2png.png';
// import { FaFacebook, FaInstagramSquare, FaYoutube } from "react-icons/fa";
// import { IoLogoWhatsapp } from "react-icons/io";

// function Footer() {
//   return (
//     <footer className="footer font-[Archivo] text-white bg-[#2D2D2D] pt-4 lg:pt-0 pb-1">
//       <div className="container mx-auto px-4 lg:px-12 py-4 lg:py-1">
//         <div className="flex flex-col lg:flex-row justify-between items-center lg:items-start space-y-8 lg:space-y-0 lg:space-x-16">
          
//           {/* Left Section */}
//           <div className="flex flex-col items-center lg:items-start space-y-4 lg:space-y-0 lg:space-x-8 lg:flex-row">
//             <div className="flex items-center space-x-4">
//               <img src={img1} alt="footer" loading="lazy" title="footer" className="w-16 h-16 object-cover" />
//               <div className="text-center lg:text-left">
//                 <h2 className="text-2xl font-bold leading-tight">
//                   Stronger <br /> Every <br /> Day
//                 </h2>
//               </div>
//             </div>
//             <div className="w-[300px] lg:w-[400px]">
//               <img src={img3} alt="footer gym" loading="lazy" title="footer" className="w-full h-auto object-cover" />
//             </div>
//           </div>

//           {/* Right Section */}
//           <div className="flex flex-col items-center lg:items-center space-y-6 lg:space-y-4 text-center">
//             <div className="flex flex-wrap justify-center lg:justify-start space-x-6 lg:space-x-12">
//               <p className="hover:underline cursor-pointer">About Us</p>
//               <p className="hover:underline cursor-pointer">Roadmap</p>
//               <p className="hover:underline cursor-pointer">Blog</p>
//               <p className="hover:underline cursor-pointer">Contact Us</p>
//               <p className="hover:underline cursor-pointer">FAQ’s</p>
//             </div>
//             <div className="lg:pt-12">
//               <h3 className="mb-4 text-xl">Follow Us</h3>
//               <div className="flex justify-center space-x-6 lg:space-x-8 items-center">
//                 <a href="#" aria-label="Facebook" className="hover:text-blue-600 transition-colors">
//                   <FaFacebook size={24} />
//                 </a>
//                 <a href="#" aria-label="Instagram" className="hover:text-pink-500 transition-colors">
//                   <FaInstagramSquare size={24} />
//                 </a>
//                 <a href="#" aria-label="YouTube" className="hover:text-red-600 transition-colors">
//                   <FaYoutube size={24} />
//                 </a>
//                 <a href="#" aria-label="WhatsApp" className="hover:text-green-500 transition-colors">
//                   <IoLogoWhatsapp size={24} />
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="text-center mt-8  lg:mt-0">
//           <p className="text-sm">© 2024 FitEspero. All rights reserved.</p>
//         </div>
//       </div>
//     </footer>
//   );
// }

// export default Footer;







import React from "react";
import img1 from "../img/Asset 1@2x 1.png";
import img3 from '../img/gymimage2png.png';
import { FaFacebook, FaInstagramSquare, FaYoutube } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";

function Footer() {
  return (
    <footer className="footer font-[Archivo] text-white bg-[#2D2D2D] pt-6 lg:pt-12 pb-6 lg:pb-12">
      <div className="container mx-auto px-4 lg:px-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
          {/* Left Section */}
          <div className="flex flex-col items-center lg:items-start lg:flex-row lg:space-x-8 space-y-6 lg:space-y-0">
            <div className="flex items-center space-x-4">
              <img src={img1} alt="footer" loading="lazy" title="footer" className="w-16 h-16 object-cover" />
              <div className="text-center lg:text-left">
                <h2 className="text-2xl font-bold leading-tight">
                  Stronger <br /> Every <br /> Day
                </h2>
              </div>
            </div>
            <div className="w-[300px] lg:w-[400px] mt-6 lg:mt-0">
              <img src={img3} alt="footer gym" loading="lazy" title="footer" className="w-full h-auto object-cover" />
            </div>
          </div>

          {/* Right Section */}
          <div className="flex flex-col items-center lg:items-start space-y-6 lg:space-y-4 text-center lg:text-left">
            <div className="flex flex-wrap justify-center lg:justify-start space-x-4 lg:space-x-8">
              <p className="hover:underline cursor-pointer">About Us</p>
              <p className="hover:underline cursor-pointer">Roadmap</p>
              <p className="hover:underline cursor-pointer">Blog</p>
              <p className="hover:underline cursor-pointer">Contact Us</p>
              <p className="hover:underline cursor-pointer">FAQ’s</p>
            </div>

            {/* Social Media Links */}
            <div className="pt-4 lg:pt-8">
              <h3 className="mb-4 text-xl">Follow Us</h3>
              <div className="flex justify-center lg:justify-start space-x-6 items-center">
                <a href="#" aria-label="Facebook" className="hover:text-blue-600 transition-colors">
                  <FaFacebook size={24} />
                </a>
                <a href="#" aria-label="Instagram" className="hover:text-pink-500 transition-colors">
                  <FaInstagramSquare size={24} />
                </a>
                <a href="#" aria-label="YouTube" className="hover:text-red-600 transition-colors">
                  <FaYoutube size={24} />
                </a>
                <a href="#" aria-label="WhatsApp" className="hover:text-green-500 transition-colors">
                  <IoLogoWhatsapp size={24} />
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* Footer bottom text */}
        <div className="text-center mt-8 lg:mt-12">
          <p className="text-sm">© 2024 FitEspero. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
