



// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import Comlogo from '../img/Asset 5@2x 1.png';
// import { FaBarsStaggered } from 'react-icons/fa6';
// import { IoMdClose } from "react-icons/io";

// function Navbar() {
//   const [isOpen, setIsOpen] = useState(false);
//   const navigate = useNavigate();

//   const toggleMenu = () => setIsOpen(!isOpen);

//   const scrollToSection = (id) => {
//     const section = document.getElementById(id);
//     if (section) {
//       section.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   const handleNavLinkClick = (path) => {
//     if (path.startsWith('#')) {
//       scrollToSection(path.substring(1)); // scroll to section
//     } else {
//       navigate(path); // navigate to route
//     }
//     setIsOpen(false); // Close menu on link click
//   };

//   const renderLinkText = (path) => {
//     if (path === '/faq') return 'FAQ';
//     return path.charAt(1).toUpperCase() + path.slice(2);
//   };

//   return (
//     <div className="bg-white text-red-600">
//       <div className="flex items-center font-[Cardo] justify-between p-4">
//         {/* Logo */}
//         <NavLink to="/" className="h-16 relative right-12 w-40 sm:w-52">
//           <img
//             src={Comlogo}
//             className="w-full h-full object-contain"
//             alt="company logo"
//             loading="lazy"
//             title="company logo img"
//           />
//         </NavLink>

//         {/* Get App Button for Small Screens */}
//         <div className="lg:hidden relative text-white sm:left-40 left-6 mr-4">
//           <NavLink 
//             to="/login"
//             className="border-[1px] bg-red-600 shadow-md shadow-red-600 rounded-md px-4 py-2 hover:bg-red-700 hover:text-white hover:shadow-red-700 hover:shadow-md hover:font-semibold"
//           >
//             Get App
//           </NavLink>
//         </div>

//         {/* Toggle Button for Mobile and Medium Screens */}
//         <button 
//           className="lg:hidden text-3xl focus:outline-none" 
//           onClick={toggleMenu}
//           aria-label="Toggle menu"
//         >
//           {isOpen ? <IoMdClose className="text-red-600" /> : <FaBarsStaggered className="text-red-600" />}
//         </button>
        
//         {/* Navigation Links for Large Screens */}
//         <div className="hidden lg:flex flex-1 justify-center items-center space-x-6 border-[1px] font-[Cardo] max-w-[600px] border-red-600 rounded-md">
//           {['/roadmap', '/blog', '/about', '#FAQ', '#contact'].map((path, index) => (
//             <span 
//               key={index}
//               onClick={() => handleNavLinkClick(path)}
//               className="px-4 py-2 cursor-pointer transition-all duration-300 ease-in-out rounded-md hover:text-red-700"
//             >
//               {renderLinkText(path)}
//             </span>
//           ))}
//         </div>

//         {/* Get App Button for Large Screens */}
//         <div className="hidden lg:flex items-center lg:mr-12">
//           <NavLink 
//             to="/login"
//             className="border-[1px] bg-red-600 cursor-pointer text-white shadow-md shadow-red-600 rounded-md px-4 py-2 hover:bg-red-700 hover:text-white hover:shadow-red-700 hover:shadow-md hover:font-semibold"
//           >
//             Get App
//           </NavLink>
//         </div>
//       </div>

//       {/* Navigation Links for Small and Medium Screens */}
//       <div className={`lg:hidden flex flex-col items-start mt-4 space-y-2 transition-all duration-300 ease-in-out ${isOpen ? 'flex' : 'hidden'}`}>
//         {['/roadmap', '/blog', '/about', '#faq', '#contact'].map((path, index) => (
//           <span 
//             key={index}
//             onClick={() => handleNavLinkClick(path)}
//             className="block px-8 py-2 cursor-pointer hover:font-semibold"
//           >
//             {renderLinkText(path)}
//           </span>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Navbar;














// import React, { useState } from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import Comlogo from '../img/Asset 5@2x 1.png';
// import { FaBarsStaggered } from 'react-icons/fa6';
// import { IoMdClose } from "react-icons/io";
// import { Button, Typography } from '@mui/material';

// function Navbar() {
//   const [isOpen, setIsOpen] = useState(false);
//   const navigate = useNavigate();

//   const toggleMenu = () => setIsOpen(!isOpen);

//   const scrollToSection = (id) => {
//     const section = document.getElementById(id);
//     if (section) {
//       section.scrollIntoView({ behavior: 'smooth' });
//     }
//   };

//   const handleNavLinkClick = (path) => {
//     if (path.startsWith('#')) {
//       scrollToSection(path.substring(1)); // scroll to section
//     } else {
//       navigate(path); // navigate to route
//     }
//     setIsOpen(false); // Close menu on link click
//   };

//   const renderLinkText = (path) => {
//     if (path === '/faq') return 'FAQ';
//     return path.charAt(1).toUpperCase() + path.slice(2);
//   };

//   return (
//     <div className="bg-white text-red-600">
//       <div className="flex items-center font-[Cardo] justify-between p-4">
//         {/* Logo */}
//         <NavLink to="/" className="h-16 relative right-12 w-40 sm:w-52">
//           <img
//             src={Comlogo}
//             className="w-full h-full object-contain"
//             alt="company logo"
//             loading="lazy"
//             title="company logo img"
//           />
//         </NavLink>

//         {/* Get App Button for Small Screens */}
//         <div className="lg:hidden relative text-white sm:left-40 left-6 mr-4">
//           <NavLink 
//             to="/login"
//             className="border-[1px] bg-red-600 shadow-md shadow-red-600 rounded-md px-4 py-2 hover:bg-red-700 hover:text-white hover:shadow-red-700 hover:shadow-md hover:font-semibold"
//           >
//             Get App
//           </NavLink>
//         </div>

//         {/* Toggle Button for Mobile and Medium Screens */}
//         <button 
//           className="lg:hidden text-3xl focus:outline-none" 
//           onClick={toggleMenu}
//           aria-label="Toggle menu"
//         >
//           {isOpen ? <IoMdClose className="text-red-600" /> : <FaBarsStaggered className="text-red-600" />}
//         </button>
        
//         {/* Navigation Links for Large Screens */}
//         <div className="hidden lg:flex flex-1 justify-center items-center space-x-6 border-[1px] font-[Cardo] max-w-[600px] border-red-600 rounded-md">
//           {['/roadmap', '/blog', '/about', '#FAQ', '#contact'].map((path, index) => (
//             <span 
//               key={index}
//               onClick={() => handleNavLinkClick(path)}
//               className="px-4 py-2 cursor-pointer transition-all duration-300 ease-in-out rounded-md hover:text-red-700"
//             >
//               {renderLinkText(path)}
//             </span>
//           ))}
//         </div>

//         {/* Get App Button for Large Screens */}
//         <div className="hidden lg:flex items-center lg:mr-12">
//           <NavLink 
//             to="/login"
//             className="border-[1px] bg-red-600 cursor-pointer text-white shadow-md shadow-red-600 rounded-md px-4 py-2 hover:bg-red-700 hover:text-white hover:shadow-red-700 hover:shadow-md hover:font-semibold"
//           >
//             Get App
//           </NavLink>
//         </div>
//       </div>

//       {/* Navigation Links for Small and Medium Screens */}
//       <div className={`lg:hidden flex flex-col items-start mt-4 space-y-2 transition-all duration-300 ease-in-out ${isOpen ? 'flex' : 'hidden'}`}>
//         {['/roadmap', '/blog', '/about', '#faq', '#contact'].map((path, index) => (
//           <span 
//             key={index}
//             onClick={() => handleNavLinkClick(path)}
//             className="block px-8 py-2 cursor-pointer hover:font-semibold"
//           >
//             {renderLinkText(path)}
//           </span>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Navbar;












import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import Comlogo from '../img/Asset 5@2x 1.png';
import { FaBarsStaggered } from 'react-icons/fa6';
import { IoMdClose } from "react-icons/io";
import { Button, Typography } from '@mui/material';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMenu = () => setIsOpen(!isOpen);

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleNavLinkClick = (path) => {
    if (path.startsWith('#')) {
      scrollToSection(path.substring(1)); // scroll to section
    } else {
      navigate(path); // navigate to route
    }
    setIsOpen(false); // Close menu on link click
  };

  const renderLinkText = (path) => {
    if (path === '/faq') return 'FAQ';
    return path.charAt(1).toUpperCase() + path.slice(2);
  };

  return (
    <div className="bg-white text-red-600">
      <div className="flex items-center font-[Cardo] justify-between p-4">
        {/* Logo */}
        <NavLink to="/" className="h-16 relative right-12 w-40 sm:w-52">
          <img
            src={Comlogo}
            className="w-full h-full object-contain"
            alt="company logo"
            loading="lazy"
            title="company logo img"
          />
        </NavLink>

        {/* Get App Button for Small Screens */}
        <div className="lg:hidden relative text-white sm:left-40 left-6 mr-4">
          <NavLink 
            to="/login"
            className="border-[1px] bg-red-600 shadow-md shadow-red-600 rounded-md px-4 py-2 hover:bg-red-700 hover:text-white hover:shadow-red-700 hover:shadow-md hover:font-semibold"
          >
            Get App
          </NavLink>
        </div>

        {/* Toggle Button for Mobile and Medium Screens */}
        <button 
          className="lg:hidden text-3xl focus:outline-none" 
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          {isOpen ? <IoMdClose className="text-red-600" /> : <FaBarsStaggered className="text-red-600" />}
        </button>
        
        {/* Navigation Links for Large Screens */}
        <div className="hidden lg:flex flex-1 justify-center items-center space-x-6 border-[1px] font-[Cardo] max-w-[600px] border-red-600 rounded-md">
          {['/roadmap', '/blog', '/about', '#FAQ', '#contact'].map((path, index) => (
            <span 
              key={index}
              onClick={() => handleNavLinkClick(path)}
              className="px-4 py-2 cursor-pointer transition-all duration-300 ease-in-out rounded-md relative group"
            >
              {renderLinkText(path)}
              {/* Underline effect */}
              <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-red-600 transition-all duration-300 group-hover:w-full"></span>
            </span>
          ))}
        </div>

        {/* Get App Button for Large Screens */}
        <div className="hidden lg:flex items-center lg:mr-12">
          <NavLink 
            to="/login"
            className="border-[1px] bg-red-600 cursor-pointer text-white shadow-md shadow-red-600 rounded-md px-4 py-2 hover:bg-red-700 hover:text-white hover:shadow-red-700 hover:shadow-md hover:font-semibold"
          >
            Get App
          </NavLink>
        </div>
      </div>

      {/* Navigation Links for Small and Medium Screens */}
      <div className={`lg:hidden flex flex-col items-start mt-4 space-y-2 transition-all duration-300 ease-in-out ${isOpen ? 'flex' : 'hidden'}`}>
        {['/roadmap', '/blog', '/about', '#faq', '#contact'].map((path, index) => (
          <span 
            key={index}
            onClick={() => handleNavLinkClick(path)}
            className="block px-8 py-2 cursor-pointer hover:font-semibold relative group"
          >
            {renderLinkText(path)}
            {/* Underline effect */}
            <span className="absolute bottom-0 left-0 w-0 h-[2px] bg-red-600 transition-all duration-300 group-hover:w-full"></span>
          </span>
        ))}
      </div>
    </div>
  );
}

export default Navbar;
