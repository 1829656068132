





import React, { useEffect, useState } from 'react';
import imgGirl from "../img/gymimage44.png";
import axios from 'axios';
import { FaPersonRunning } from 'react-icons/fa6';
import { MdPersonAddAlt, MdOnDeviceTraining } from 'react-icons/md';
import { FaHandsHelping } from 'react-icons/fa';
import { API_BASE_URL } from '../config/api';
import { Typography } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

function HomeAbout() {
  const [content, setContent] = useState({});
  const [error, setError] = useState(null);

  // Fetch content data
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const { data } = await axios.get(`${API_BASE_URL}/api/users/homeabout`);
        console.log('Fetched homeabout data:', data);
        if (data.status === 'success' && data.data && data.data.length > 0) {
          setContent(data.data[0]);
        } else {
          setError('No content found.');
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError('Content not found. Please check the API endpoint.');
        } else {
          setError(`Error fetching content: ${err.message}`);
        }
        console.error('Error fetching content:', err);
      }
    };

    fetchContent();
  }, []);

  // Initialize AOS for scroll animations
  useEffect(() => {
    AOS.init({
      duration: 1000, // Duration of animations (1000ms = 1s)
      easing: 'ease-in-out', // Easing for animations
      once: false, // Disable this to trigger animation every time element comes into view
      mirror: true, // Repeat animation when scrolling up and down
    });
  }, []);

  return (
    <div className="font-[AnekOdia] homeabout mt-12 p-4 xs:p-6 sm:p-8 md:p-12 lg:p-16 flex flex-col md:flex-row justify-between items-center space-y-6 md:space-y-0">
      
      {/* Left Section */}
      <div className="text-center md:text-left md:w-1/2 mb-8" data-aos="fade-right" >
        <Typography variant="h6" color="textSecondary">About</Typography>
        <Typography variant="h2" className="xs:text-xl md:text-3xl lg:text-6xl font-semibold flex justify-center md:justify-start items-center space-x-2">
          <span className="text-black">FIT</span>
          <span className="text-red-600">ESPERO</span>
        </Typography>
        <div className="mt-4 sm:mt-6 md:mt-8 xs:max-w-xs sm:max-w-sm mx-auto md:mx-0">
          <img src={imgGirl} alt="Fitness Girl" className="w-full h-auto" />
        </div>
      </div>

      {/* Right Section */}
      <div className="md:w-1/2 space-y-5" data-aos="fade-left">
        <Typography variant="h4" className="xs:text-xl md:text-3xl lg:text-4xl font-bold mb-4 flex justify-center md:justify-start items-center space-x-2">
          <span className="text-red-600">{content.heading || 'TRANSFORM'}</span>
          <span className="text-black">{content.heading ? '' : 'YOUR'}</span>
        </Typography>
        <Typography variant="body1" color="textSecondary" className="text-center md:text-left text-sm xs:text-base sm:text-lg max-w-sm md:max-w-md mx-auto md:mx-0">
          {content.subheading || 
            `Welcome to FIT ESPERO, where fitness meets community in the heart of [City/Area]. We provide a welcoming space where everyone, from beginners to seasoned athletes, can achieve their fitness goals.`}
        </Typography>

        {/* Our Services */}
        <div>
          <Typography variant="h6" color="textSecondary" className="text-center md:text-left mb-4">Our Services</Typography>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 md:grid-cols-2 lg:grid-cols-4">
            <div className="flex flex-col items-center" data-aos="zoom-in">
              <div className="bg-red-600 text-white w-16 h-16 sm:w-20 sm:h-20 rounded-full flex justify-center items-center mb-2 sm:mb-4">
                <FaHandsHelping className="w-8 h-8 sm:w-10 sm:h-10" />
              </div>
              <Typography variant="body2" color="textSecondary">Personal Training</Typography>
            </div>
            <div className="flex flex-col items-center" data-aos="zoom-in" data-aos-delay="100">
              <div className="bg-red-600 text-white w-16 h-16 sm:w-20 sm:h-20 rounded-full flex justify-center items-center mb-2 sm:mb-4">
                <FaPersonRunning className="w-8 h-8 sm:w-10 sm:h-10" />
              </div>
              <Typography variant="body2" color="textSecondary">Fitness Classes</Typography>
            </div>
            <div className="flex flex-col items-center" data-aos="zoom-in" data-aos-delay="200">
              <div className="bg-red-600 text-white w-16 h-16 sm:w-20 sm:h-20 rounded-full flex justify-center items-center mb-2 sm:mb-4">
                <MdOnDeviceTraining className="w-8 h-8 sm:w-10 sm:h-10" />
              </div>
              <Typography variant="body2" color="textSecondary">Virtual and On-Demand Fitness</Typography>
            </div>
            <div className="flex flex-col items-center" data-aos="zoom-in" data-aos-delay="300">
              <div className="bg-red-600 text-white w-16 h-16 sm:w-20 sm:h-20 rounded-full flex justify-center items-center mb-2 sm:mb-4">
                <MdPersonAddAlt className="w-8 h-8 sm:w-10 sm:h-10" />
              </div>
              <Typography variant="body2" color="textSecondary">Membership Options and Pricing</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeAbout;
