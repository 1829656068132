


import React from "react";
import Landing from "../Pages/Landing";
import Partners from "../Pages/Partners";
import HomeAbout from "../Pages/HomeAbout";
import SocialMedia from "../Pages/SocialMedia";
import HomeFaq from "../Pages/HomeFaq";
import Contact from "../Pages/Contact";
import Footer from "../Pages/Footer";
import ImgSliders from "../Pages/ImgSliders";



function Home() {
  return (
<div className=" home">
<Landing  />
<ImgSliders/>
<Partners/>
<HomeAbout/>
<SocialMedia/>
<HomeFaq  />
<Contact/>
<Footer/>
</div>
  );
}

export default Home;
