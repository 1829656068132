



import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import { API_BASE_URL } from '../config/api';
import { Grid, Typography, CircularProgress } from '@mui/material';

function ImgSliders() {
    const [partnerLogos, setPartnerLogos] = useState([]);

    // Fetch partner logos from the API
    useEffect(() => {
        const fetchLogos = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/api/upload`);
                console.log("pat", response.data);
                setPartnerLogos(response.data.data); // Correctly accessing the image data
            } catch (error) {
                console.error("Error fetching partner logos:", error);
            }
        };

        fetchLogos();
    }, []);

    // Slider settings for continuous scrolling
    const settings = {
        dots: false, 
        infinite: true, 
        speed: 5000, 
        slidesToShow: 6, 
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0, 
        cssEase: "linear", 
        pauseOnHover: false, 
        swipeToSlide: false, 
        arrows: false, 
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    };

    return (
        <div className="font-[AnekOdia] pt-6 md:pt-12">
            {/* News heading */}
            <div className="mb-4 text-center">
                <Typography variant="h3" className="font-[900]  leading-tight text-gray-800">
                    Partner Logos <span className="text-red-600">in the news</span>
                </Typography>
            </div>

            {/* Partner Logos Slider */}
            <div className="px-4 md:px-12">
                {partnerLogos.length > 0 ? (
                    <Slider {...settings}>
                        {partnerLogos.map((logo, index) => (
                            <div key={index} className="flex justify-center">
                                <img 
                                    src={`${API_BASE_URL}${logo.url}`} 
                                    alt={`Partner ${index + 1}`} 
                                    className="w-[60px] xs:w-[80px] sm:w-[100px] md:w-[120px] h-auto" 
                                />
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div className="flex justify-center items-center py-10">
                        <CircularProgress className="text-red-600" />
                    </div>
                )}
            </div>
        </div>
    );
}

export default ImgSliders;
