

import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import img1 from '../img/gym img4.png';
import img2 from '../img/Rectangle 56.png';
import img3 from '../img/Rectangle 57.png';
import img4 from '../img/Rectangle 58.png';
import img5 from '../img/pngwing.com (3) 1.png';
import axios from 'axios';
import { API_BASE_URL } from '../config/api'; 
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';

function SocialMedia() {
  const [offerData, setOfferData] = useState({});
  const [error, setError] = useState(null);

  // Initialize AOS
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true, // Whether animation should happen only once
    });
  }, []);

  // Fetch content data
  useEffect(() => {
    const fetchOfferData = async () => {
      try {
        const { data } = await axios.get(`${API_BASE_URL}/api/users/offer`);
        console.log('Fetched offers data:', data);
        if (data.status === 'success' && data.data.length > 0) {
          setOfferData(data.data[0]);
        } else {
          setError('No content found.');
        }
      } catch (err) {
        if (err.response && err.response.status === 404) {
          setError('Content not found. Please check the API endpoint.');
        } else {
          setError(`Error fetching content: ${err.message}`);
        }
        console.error('Error fetching content:', err);
      }
    };

    fetchOfferData();
  }, []);

  return (
    <div className="p-6 font-[Archivo] social mt-12 lg:mt-0 mb-20">
      {/* First Section */}
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-12">
        {/* Main Image and Text */}
        <div className="relative text-center md:text-left flex flex-col items-center md:items-start">
          {/* Heading with animation */}
          <h2
            className="text-[3rem] sm:text-[4rem] md:text-[6rem] lg:text-[8rem] font-bold text-[#FFAFAF] z-20 lg:relative absolute top-0 lg:top-16 md:top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            data-aos="fade-up"
          >
            Social
          </h2>
          <img
            src={img1}
            alt="Main visual"
            loading="lazy"
            title="Load"
            className="relative w-full max-w-xs sm:max-w-sm md:max-w-md mx-auto transform transition-transform duration-500 ease-in-out hover:scale-105"
            data-aos="fade-up"
            data-aos-delay="200"
          />
          <h2
            className="text-[3rem] sm:text-[4rem] md:text-[6rem] lg:text-[8rem] font-bold text-[#FFAFAF] relative mt-[-2rem] sm:mt-[-3rem] md:mt-[-4rem] lg:mt-[-5rem]"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Media
          </h2>
        </div>

        {/* Image Grid */}
        <Grid container className=' lg:pl-0 md:pl-0 pl-20' spacing={2} data-aos="fade-up" data-aos-delay="600">
          <Grid item xs={12} sm={6} md={4}>
            <img
              src={img2}
              alt="Image 1"
              loading="lazy"
              title="Load"
              className="rounded-lg shadow-md transform transition duration-300 hover:scale-105"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img
              src={img3}
              alt="Image 2"
              loading="lazy"
              title="Load"
              className="rounded-lg shadow-md transform transition duration-300 hover:scale-105"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img
              src={img4}
              alt="Image 3"
              loading="lazy"
              title="Load"
              className="rounded-lg shadow-md transform transition duration-300 hover:scale-105"
            />
          </Grid>
        </Grid>
      </div>

      {/* Second Section */}
      <div className="container mt-12 mx-auto flex flex-col md:flex-row justify-between items-center space-y-8 md:space-y-0 md:space-x-12 p-6">
        {/* Promotional Image and Text */}
        <div
          className="relative flex flex-col md:flex-row items-center md:items-start text-center md:text-left"
          data-aos="fade-left"
        >
          <img
            src={img5}
            alt="Promotional image"
            loading="lazy"
            title="Promotional Image"
            className="relative w-full max-w-xs sm:max-w-sm md:max-w-md drop-shadow-xl 5"
          />
          <div className="text-[#DA0000] mt-6 md:mt-0 pl-4 md:pl-8 max-w-full md:mx-0">
            <h2
              className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 leading-tight tracking-wide"
              data-aos="fade-left"
              data-aos-delay="200"
            >
              {offerData?.title || 'We Offer You'}
            </h2>
            <p
              className="text-base sm:text-lg md:text-xl text-gray-700 leading-relaxed"
              data-aos="fade-left"
              data-aos-delay="600"
            >
              {offerData?.description || 'Summer Kickoff Sale: 30% Off All Memberships!'}
              <span className="text-black font-extrabold text-2xl block mt-3 leading-snug">
                {offerData?.sub_title || 'Save Big'}
              </span>
              {offerData?.sub_description || 'Save on annual memberships until the end of the season.'}
            </p>
            {/* Optional CTA button */}
            <Button
              variant="contained"
              color="error"
              className="mt-6 px-6 py-3"
              data-aos="fade-left"
              data-aos-delay="800"
            >
              Join Now !
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialMedia;
