








// import React, { useEffect, useState } from "react";
// import imgGirl from "../img/gymimage44.png";
// import imgBoy from "../img/pngwing.com (28) 1.png";
// import Footer from "../Pages/Footer";
// import axios from "axios";
// import { API_BASE_URL } from '../config/api';
// import Typography from '@mui/material/Typography';
// import visionGirl from "../img/pngwing.com (41) 1.png";

// function About() {
//   const [contents, setContents] = useState([[], [], []]); // Array to hold the content from all endpoints

//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const endpoints = [
//           `${API_BASE_URL}/api/contentfirst`, // For the first content section
//           `${API_BASE_URL}/api/contentSecond`, // For "Our Story" section
//           `${API_BASE_URL}/api/contentthird` // For "Final Content" section
//         ];

//         const responses = await Promise.all(endpoints.map(endpoint => axios.get(endpoint)));

//         // Set the state with responses
//         const fetchedContents = responses.map(response => response.data.status === "success" ? response.data.data : []);
//         setContents(fetchedContents);
//       } catch (error) {
//         console.error("Error fetching content:", error);
//       }
//     };

//     fetchData();
//   }, []);

//   return (
//     <div className="font-[AnekBangla] mt-12">
//       {/* Header */}
//       <div className="text-center py-6 mx-4 lg:mx-20 rounded-md bg-[#3f2323]">
//         <Typography variant="h5" className="text-white">About</Typography>
//         <Typography variant="h3" className="text-white">
//           FIT<span className="text-red-600"> ESPERO</span>
//         </Typography>
//       </div>

//       {/* Introduction Section */}
//       <div className="flex flex-col items-center my-8 mx-4 lg:mx-20">
//         <div className="w-full md:w-1/2">
//           <img src={imgGirl} alt="Girl" className="w-full h-auto rounded-md" loading="lazy" />
//         </div>
//         <div className="text-center text-black mt-6">
//           <Typography variant="h6">About</Typography>
//           <Typography variant="h3">
//             FIT<span className="text-red-600"> ESPERO</span>
//           </Typography>
//         </div>
//       </div>

//       {/* First Content Section */}
//       <div className="mx-4 lg:mx-20 space-y-8">
//         <ul className="space-y-2">
//           {contents[0].map((item) => (
//             <li key={item.id} className="flex justify-between items-center  py-2">
//               <div>
//               <Typography variant="h6" className=" text-red-600">{item.heading}</Typography>
//               <Typography variant="p" >{item.subheading}</Typography>

                 
//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Thank You Section */}
//       <div className="flex flex-col md:flex-row items-center justify-between text-white p-6 mx-4 lg:mx-20 mt-12 rounded-md bg-[#BF0000] space-y-4 md:space-y-0">
//         <div className="w-[200px]">
//           <img src={imgBoy} alt="Boy" className="w-full h-auto rounded-md" loading="lazy" />
//         </div>
//         <div className="text-center md:text-left md:w-2/3">
//           <Typography variant="h6" className="text-2xl">We Want To Thank You For Helping Us Make</Typography>
//           <Typography variant="h6" className="text-2xl">Our Products Better and Better</Typography>
//           <button className="bg-[#900000] mt-12 py-2 px-6 rounded-md">Join Us</button>
//         </div>
//       </div>

//       {/* Our Story Section */}
//       <div className="mx-4 lg:mx-20 mt-12 space-y-8">
//         <Typography variant="h3" className="text-3xl text-center">
//           OUR<span className="text-red-600"> STORY</span>
//         </Typography>
//         <ul className="space-y-2">
//           {contents[1].map((item) => (
//             <li key={item.id} className="flex justify-between items-center  py-2">
//               <div>
//               <Typography variant="h6" className=" text-red-600">{item.heading}</Typography>
//               <Typography variant="p" >{item.subheading}</Typography>

//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>


//                {/* Vision Section */}
//          <div className="flex justify-center text-white p-6 mx-4 lg:mx-20 mt-12 rounded-md bg-[#BF0000] space-y-4 md:space-y-0">
//            <div className="">
//              <h2 className="text-4xl relative left-20">VISION</h2>
//            </div>
//            <div className="w-[200px]">
//              <img src={visionGirl} alt="Vision Girl" className="w-full h-auto" loading="lazy" />
//            </div>
//          </div>


//       {/* Final Content Section */}
//       <div className="mx-4 lg:mx-20 mt-12 mb-20 space-y-4">
//         {/* <Typography variant="h3" className="text-3xl text-center">Final Content</Typography> */}
//         <ul className="space-y-2">
//           {contents[2].map((item) => (
//             <li key={item.id} className="flex justify-between items-center py-2">
//               <div>
//               <Typography variant="h6" className=" text-red-600">{item.heading}</Typography>
//               <Typography variant="p" >{item.subheading}</Typography>

//               </div>
//             </li>
//           ))}
//         </ul>
//       </div>

//       <Footer />
//     </div>
//   );
// }

// export default About;



























import React, { useEffect, useState } from "react";
import imgGirl from "../img/gymimage44.png";
import imgBoy from "../img/pngwing.com (28) 1.png";
import Footer from "../Pages/Footer";
import axios from "axios";
import { API_BASE_URL } from '../config/api';
import Typography from '@mui/material/Typography';
import visionGirl from "../img/pngwing.com (41) 1.png";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles

function About() {
  const [contents, setContents] = useState([[], [], []]); // Array to hold the content from all endpoints

  useEffect(() => {
    AOS.init({ 
      duration: 2000, // Duration of animations (1000ms = 1s)
      easing: 'ease-in-out', // Easing for animations
      once: false, // Disable this to trigger animation every time element comes into view
      mirror: true, 
     }); // Initialize AOS with custom settings
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoints = [
          `${API_BASE_URL}/api/contentfirst`, // For the first content section
          `${API_BASE_URL}/api/contentSecond`, // For "Our Story" section
          `${API_BASE_URL}/api/contentthird` // For "Final Content" section
        ];

        const responses = await Promise.all(endpoints.map(endpoint => axios.get(endpoint)));

        // Set the state with responses
        const fetchedContents = responses.map(response => response.data.status === "success" ? response.data.data : []);
        setContents(fetchedContents);
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="font-[AnekBangla] mt-12">
      {/* Header */}
      <div className="text-center py-6 mx-4 lg:mx-20 rounded-md bg-[#3f2323]" data-aos="fade-down">
        <Typography variant="h5" className="text-white">About</Typography>
        <Typography variant="h3" className="text-white">
          FIT<span className="text-red-600"> ESPERO</span>
        </Typography>
      </div>

      {/* Introduction Section */}
      <div className="flex flex-col items-center my-8 mx-4 lg:mx-20" data-aos="fade-up">
        <div className="w-full md:w-1/2">
          <img src={imgGirl} alt="Girl" className="w-full h-auto rounded-md" loading="lazy" />
        </div>
        <div className="text-center text-black mt-6">
          <Typography variant="h6">About</Typography>
          <Typography variant="h3">
            FIT<span className="text-red-600"> ESPERO</span>
          </Typography>
        </div>
      </div>

      {/* First Content Section */}
      <div className="mx-4 lg:mx-20 space-y-8" data-aos="fade-right">
        <ul className="space-y-2">
          {contents[0].map((item) => (
            <li key={item.id} className="flex justify-between items-center py-2">
              <div>
                <Typography variant="h6" className=" text-red-600">{item.heading}</Typography>
                <Typography variant="p">{item.subheading}</Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Thank You Section */}
      <div className="flex flex-col md:flex-row items-center justify-between text-white p-6 mx-4 lg:mx-20 mt-12 rounded-md bg-[#BF0000] space-y-4 md:space-y-0" data-aos="zoom-in">
        <div className="w-[200px]">
          <img src={imgBoy} alt="Boy" className="w-full h-auto rounded-md" loading="lazy" />
        </div>
        <div className="text-center md:text-left md:w-2/3">
          <Typography variant="h6" className="text-2xl">We Want To Thank You For Helping Us Make</Typography>
          <Typography variant="h6" className="text-2xl">Our Products Better and Better</Typography>
          <button className="bg-[#900000] mt-12 py-2 px-6 rounded-md">Join Us</button>
        </div>
      </div>

      {/* Our Story Section */}
      <div className="mx-4 lg:mx-20 mt-12 space-y-8" data-aos="fade-left">
        <Typography variant="h3" className="text-3xl text-center">
          OUR<span className="text-red-600"> STORY</span>
        </Typography>
        <ul className="space-y-2">
          {contents[1].map((item) => (
            <li key={item.id} className="flex justify-between items-center py-2">
              <div>
                <Typography variant="h6" className=" text-red-600">{item.heading}</Typography>
                <Typography variant="p">{item.subheading}</Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Vision Section */}
      <div className="flex justify-center text-white p-6 mx-4 lg:mx-20 mt-12 rounded-md bg-[#BF0000] space-y-4 md:space-y-0" data-aos="flip-up">
        <div className="">
          <h2 className="text-4xl relative left-20">VISION</h2>
        </div>
        <div className="w-[200px]">
          <img src={visionGirl} alt="Vision Girl" className="w-full h-auto" loading="lazy" />
        </div>
      </div>

      {/* Final Content Section */}
      <div className="mx-4 lg:mx-20 mt-12 mb-20 space-y-4" data-aos="fade-up">
        <ul className="space-y-2">
          {contents[2].map((item) => (
            <li key={item.id} className="flex justify-between items-center py-2">
              <div>
                <Typography variant="h6" className=" text-red-600">{item.heading}</Typography>
                <Typography variant="p">{item.subheading}</Typography>
              </div>
            </li>
          ))}
        </ul>
      </div>

      <Footer />
    </div>
  );
}

export default About;
